<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                Database Update Procedure
              </h5>
            </div>

            <div class="header__right">            
              <v-flex>                                 
                <button class="jcon_add" @click="setAdd()"></button>
                <!-- <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div> -->
              </v-flex>
              <!-- <button @click="excel()">EXCEL</button> -->
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            hide-actions
            :headers="setheaders"
            :items="Updateditems"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus($event, props)">
                <td>{{ props.item.updateItem }}</td>
                <td>{{ props.item.updated }}</td>
                <td v-if="UpdateVisible" @click="orindbTranster(props)">Original Update</td>
                <td v-if="UpdateVisible" @click="devdbTranster(props)">Dev Update</td>
                <td v-if="UpdateVisible" @click="tortuedbTranster(props)">Tortue Update</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>
    </div>

    <j-form-modal
      title="Update Name"
      ref="formHandler"
      @create="add()"
      @edit="edit()"
      @delete="del()"
      @cancel="cancel()"
      :formMode="formode"
      :resetable="resetable()"
      :opened.sync="modOpen"
      class="type01"
    >
      <v-container>
        <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.updateItem"
                required
                label="Update Name"
                placeholder="Input/Edit update Name"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
        </v-layout>
      </v-container>
    </j-form-modal>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { AdminService, SystemCodeService, SystemService} from "@/services"
import JFormModal from "@/components/floating/JFormModal"

export default {
  name: "svccontrol-user-account",
  components: {
    JFormModal
  },
  data: () => ({
    UpdateVisible: true,
    adminService: null,
    systemService: null,
    systemCodeService: null,
    modOpen: false,
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      {
        type: "text",
        text: "Update Name",
        align: "left",
        sortable: true,
        value: "updateItem",
        width: 100
      },
      {
        type: "text",
        text: "Updated",
        align: "left",
        sortable: false,
        value: "updated",
        width: 50
      },
      { type: "text", text: "Original Update", align: "left", value: "update",  width: 50 },
      { type: "text", text: "Dev Update", align: "left", value: "update",  width: 50 },
      { type: "text", text: "Tortue Update", align: "left", value: "update",  width: 50 }
    ],
    Updateditems: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  created() {
    this.adminService = new AdminService()
    this.systemService = new SystemService()
    this.systemCodeService = new SystemCodeService()
  },
  computed: {
    setheaders() {
      let target = `${__C.HOST_NAME_RESOURCE}`
      if (target == 'https://tortue.jinsolutions.com/resources' || target == 'https://demo.jinsolutions.com/resources' ) {
        this.headers.pop()
        let lastInd = this.headers.length - 3
        delete this.headers[lastInd].width
        this.UpdateVisible = false
      }
      return this.headers
    },
  },
  mounted() {
    this.adminService.getUpdateItems(res => {
       this.setItems(res)
    })
  },
  methods: {
    setItems(res) {
      if (res.data.length == 0) this.Updateditems = []
      else this.Updateditems = res.data 
      this.modOpen = false
    },
    orindbTranster(props) {
      const _self = this
      props.item.server = "origin"
      this.yes = () => {
        this.msgOpen = false
        this.systemService.orignUpdate(props.item, res => {
          _self.menuUpdated = true
        })
        this.yes = () => {}
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Original Update"
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Item Update?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },
    devdbTranster(props) {
      const _self = this
      props.item.server = "dev"
      this.yes = () => {
        this.msgOpen = false
        this.systemService.orignUpdate(props.item, res => {
          _self.menuUpdated = true
        })
        this.yes = () => {}
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Dev Uodate"
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Item Update?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },  
    tortuedbTranster(props) {
      const _self = this
      props.item.server = "tortue"
      this.yes = () => {
        this.msgOpen = false
        this.systemService.orignUpdate(props.item, res => {
          _self.menuUpdated = true
        })
        this.yes = () => {}
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Tortue Update"
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Item Update?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },
    add() {
      this.yes = () => {
        this.msgOpen = false;
        this.adminService.putUpdateItems(this.selectedItem, () => {})
        this.yes = () => {};
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to add new Member?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgInfo.button = [true, false, true];
      this.msgOpen = true;
      
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;
        this.selectedItem.prevItem = this.selectedPrevItem[0].updateItem
        this.adminService.editUpdateItems(this.selectedItem, () => {})
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.adminService.delUpdateItems(this.selectedItem, res => {})
        this.yes = () => { };
      };
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected Member?";
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    propStatus(e, props) {
      if (e.target.textContent == 'Dev Update' || e.target.textContent == 'Tortue Update' 
      ||  e.target.textContent == 'Original Update') this.modOpen = false
      else {
        this.selectedIndex = this.Updateditems.indexOf(props.item)
        this.selectedItem = Object.assign({}, props.item)

        if (this.selectedPrevItem.length > 0) {
          if (this.selectedPrevItem[0].updateItem === props.item.updateItem) {
            this.modOpen = true
            return
          } else {
            let _selected = props.item
            this.selected = [_selected]
          }
        } else {
          props.selected = !props.selected
        }
        this.selectedPrevItem = [...this.selected]
        this.formode = __C.FORM.EDIT_MODE_MOD

        this.modOpen = true
      }
    },
    setAdd() {
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset();
      this.modOpen = true;
    },
    setEdit(selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.navItem[this.selectedIndex]);
      this.selected = [Object.assign({}, this.navItem[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          !!this.selectedItem.updateItem ||
          !!this.selectedItem.updated
        )
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].updateItem != this.selectedItem.updateItem ||
          this.selectedPrevItem[0].updated != this.selectedItem.updated
        )
          return true;
        return false;
      }
    },
  }
};
</script>
